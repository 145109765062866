.table-mc thead tr th {
   /*  border: none;
    border-right: 1px solid rgb(219, 218, 218);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    background-color: rgb(80, 78, 78);
    color: white; */
   /*  border-radius: 12px; */
   border: none;
    border-right: 1px solid rgb(219, 218, 218);
    box-shadow: 0 .3rem 1rem rgb(0 0 0 / 56%) !important;
    background-color: #3f3f3f;
    color: white;
}
.table-mc {
   background-color: transparent;
}

.table-mc tbody tr td {
    border: none;
    padding: 0%;
    margin: 0%;
    border-right: 1px solid rgb(219, 218, 218);
    border-bottom: 1px solid rgb(219, 218, 218);

    /* box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important; */
}

.td-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.content-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 10px;
    min-height: 92px;
}
